* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}


:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --sidebar-bg-color: #f5f5f5;
  --sidebarButton-bg-color: #7AA7D2;
  --sidebarButton-hv-color: #6891B8;
  --sidebarButtonCo-bg-color: #d2d27a;
  --sidebarButtonCo-hv-color: #b8b868;
  --sidebarButtonUser-bg-color: #af9eb0;
  --sidebarButtonUser-hv-color: #a38ea4;
  --log-entry-bg-color: #ffffff;
  --iconbar-bg-color: #D0E8FF; /* Light theme iconbar background */
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #121212;
    --text-color: #ffffff;
    --sidebar-bg-color: #242424;
    --sidebarButton-bg-color: #4178A0;
    --sidebarButton-hv-color: #2E5A8D;
    --sidebarButtonCo-bg-color: #aeae3b;
    --sidebarButtonCo-hv-color: #b8b868;
    --sidebarButtonUser-bg-color: #8d748e;
    --sidebarButtonUser-hv-color: #786279;
    --log-entry-bg-color: #1e1e1e;
    --iconbar-bg-color: #162238; /* Dark theme iconbar background */
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

#app {
  display: flex;
}

#iconbar {
  width: 5%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 20;
  padding-right: 5;
  padding-bottom: 20;
  padding-left: 5;
  background-color: var(--iconbar-bg-color)!important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#iconbar div {
  cursor: pointer;
  margin: 5px 0;
  /* Add more styling for the icons */
}

#sidebar {
  width: 15%;
  margin-left: 6%;
  padding-top: 20;
  padding-bottom: 20;
  background-color: var(--sidebar-bg-color);
  height: 100vh;
  overflow-y: auto;
}

.sidebarDate {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  text-align: right;
  padding: 5px;
  padding-right: 15px;
  border-radius: 5px; /* Adjust the radius as needed for round corners */
  background-color: var(--log-entry-bg-color);
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  margin-bottom: 3px; /* Optional: adds a little space between each date */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.sidebarButton {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  text-align: center;
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--sidebarButton-bg-color);
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}

.sidebarButton:hover {
  background-color: var(--sidebarButton-hv-color); 
}

.sidebarButton.company {
  background-color: var(--sidebarButtonCo-bg-color);
}

.sidebarButton.company:hover {
  background-color: var(--sidebarButtonCo-hv-color); 
}

.sidebarButton.user {
  background-color: var(--sidebarButtonUser-bg-color);
}

.sidebarButton.user:hover {
  background-color: var(--sidebarButtonUser-hv-color); 
}


#log-content {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  width: 80%;
  padding: 20px;
  background-color: var(--sidebar-bg-color);
  height: 100vh;
  overflow-y: auto;
}

.logH1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0;
  margin-block-end: 12px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  }

.formInput {
  font-family: 'Roboto', sans-serif;
  font-size: large; /* Adjust as needed to make the text larger */
  font-weight: 700;
  display: block;
  width: 75%;
  border-radius: 20px;
  border: none; /* No border for a modern look */
  padding: 10px 15px;
  margin-bottom: 10px; /* Adjust as needed for space between inputs */
}

.formSelect {
  font-family: 'Roboto', sans-serif;
  font-size: large; /* Adjust as needed to make the text larger */
  font-weight: 700;
  text-align: right;
  display: block;
  width: 25%;
  border-radius: 20px;
  border: none; /* No border for a modern look */
  padding: 10px 15px;
  margin-bottom: 10px; /* Adjust as needed for space between inputs */
}

.formButton {
  font-family: 'Roboto', sans-serif;
  font-size: medium;
  font-weight: 700;
  text-align: center;
  color: var(--text-color);
  border: none; /* No border for a modern look */
  padding: 10px 15px;
  width: 25%;
  border-radius: 20px; /* Adjust the radius as needed for round corners */
  background-color: var(--sidebarButton-bg-color);
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  margin-bottom: 10px; /* Optional: adds a little space between each element */
  margin-right: 10px; /* Optional: adds a little space between each element */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.formSpacer {
  margin-bottom: 30px; /* Optional: adds a little space between each element */
}

.apiResponse {
  font-family: 'Roboto', sans-serif;
  font-size: Medium; /* Adjust as needed to make the text larger */
  font-weight: 400;
  display: block;
  width: 75%;
  border-radius: 20px;
  border: none; /* No border for a modern look */
  padding: 10px 15px;
  margin-bottom: 10px; /* Adjust as needed for space between inputs */
}

.contentRow {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  text-align: left;
  padding: 8px;
  background-color: var(--log-entry-bg-color);
  margin-bottom: 1px; /* Optional: adds a little space between each date */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}


/*--    KANBAN    --*/
.board {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  /*background-color: #f4f0ec ;*/
  background-position: center;
  background-size: cover;
}

/* FORM */
#todo-form {
  padding: 32px 32px 0;
}

#todo-form input {
  padding: 12px;
  margin-right: 12px;
  width: 325px;

  border-radius: 4px;
  border: none;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  background: white;

  font-size: 14px;
  outline: none;
}

#todo-form button {
  padding: 12px 32px;

  border-radius: 4px;
  border: none;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  color: black;

  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

/* BOARD */
.lanes {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 16px;

  padding: 24px 32px;

  overflow: scroll;
  height: 100%;
}

.heading {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
}

.swim-lane {
  display: flex;
  flex-direction: column;
  gap: 12px;

  background: #f4f4f4;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);

  padding: 12px;
  border-radius: 4px;
  width: 325px;
  min-height: 120px;

  flex-shrink: 0;
}

.task {
  background: white;
  color: black;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

  padding: 12px;
  border-radius: 4px;

  font-size: 16px;
  cursor: move;
}

.is-dragging {
  scale: 1.05;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  background: rgb(50, 50, 50);
  color: white;
}