* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --bg-color: #fff;
  --text-color: #000;
  --sidebar-bg-color: #f5f5f5;
  --sidebarButton-bg-color: #7aa7d2;
  --sidebarButton-hv-color: #6891b8;
  --sidebarButtonCo-bg-color: #d2d27a;
  --sidebarButtonCo-hv-color: #b8b868;
  --sidebarButtonUser-bg-color: #af9eb0;
  --sidebarButtonUser-hv-color: #a38ea4;
  --log-entry-bg-color: #fff;
  --iconbar-bg-color: #d0e8ff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #121212;
    --text-color: #fff;
    --sidebar-bg-color: #242424;
    --sidebarButton-bg-color: #4178a0;
    --sidebarButton-hv-color: #2e5a8d;
    --sidebarButtonCo-bg-color: #aeae3b;
    --sidebarButtonCo-hv-color: #b8b868;
    --sidebarButtonUser-bg-color: #8d748e;
    --sidebarButtonUser-hv-color: #786279;
    --log-entry-bg-color: #1e1e1e;
    --iconbar-bg-color: #162238;
  }
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

#app {
  display: flex;
}

#iconbar {
  flex-direction: column;
  align-items: center;
  width: 5%;
  height: 100vh;
  padding: 20px 5px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: var(--iconbar-bg-color) !important;
}

#iconbar div {
  cursor: pointer;
  margin: 5px 0;
}

#sidebar {
  background-color: var(--sidebar-bg-color);
  width: 15%;
  height: 100vh;
  margin-left: 6%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
}

.sidebarDate {
  text-align: right;
  background-color: var(--log-entry-bg-color);
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 5px 15px 5px 5px;
  font-family: Roboto, sans-serif;
  font-size: small;
  transition: background-color .3s;
}

.sidebarButton {
  text-align: center;
  color: var(--text-color);
  background-color: var(--sidebarButton-bg-color);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 15px;
  font-family: Roboto, sans-serif;
  font-size: small;
  transition: background-color .3s;
}

.sidebarButton:hover {
  background-color: var(--sidebarButton-hv-color);
}

.sidebarButton.company {
  background-color: var(--sidebarButtonCo-bg-color);
}

.sidebarButton.company:hover {
  background-color: var(--sidebarButtonCo-hv-color);
}

.sidebarButton.user {
  background-color: var(--sidebarButtonUser-bg-color);
}

.sidebarButton.user:hover {
  background-color: var(--sidebarButtonUser-hv-color);
}

#log-content {
  background-color: var(--sidebar-bg-color);
  width: 80%;
  height: 100vh;
  padding: 20px;
  font-family: Roboto, sans-serif;
  font-size: small;
  overflow-y: auto;
}

.logH1 {
  margin-block: 0 12px;
  margin-inline: 0;
  font-size: 2em;
  font-weight: bold;
  display: block;
}

.formInput {
  border: none;
  border-radius: 20px;
  width: 75%;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 700;
  display: block;
}

.formSelect {
  text-align: right;
  border: none;
  border-radius: 20px;
  width: 25%;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 700;
  display: block;
}

.formButton {
  text-align: center;
  color: var(--text-color);
  background-color: var(--sidebarButton-bg-color);
  cursor: pointer;
  border: none;
  border-radius: 20px;
  width: 25%;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  font-family: Roboto, sans-serif;
  font-size: medium;
  font-weight: 700;
  transition: background-color .3s;
}

.formSpacer {
  margin-bottom: 30px;
}

.apiResponse {
  border: none;
  border-radius: 20px;
  width: 75%;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-family: Roboto, sans-serif;
  font-size: medium;
  font-weight: 400;
  display: block;
}

.contentRow {
  text-align: left;
  background-color: var(--log-entry-bg-color);
  margin-bottom: 1px;
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: small;
  transition: background-color .3s;
}

.board {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

#todo-form {
  padding: 32px 32px 0;
}

#todo-form input {
  background: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 325px;
  margin-right: 12px;
  padding: 12px;
  font-size: 14px;
  box-shadow: 0 5px 15px #00000040;
}

#todo-form button {
  color: #000;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 5px 15px #00000040;
}

.lanes {
  justify-content: start;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
  padding: 24px 32px;
  display: flex;
  overflow: scroll;
}

.heading {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
}

.swim-lane {
  background: #f4f4f4;
  border-radius: 4px;
  flex-direction: column;
  flex-shrink: 0;
  gap: 12px;
  width: 325px;
  min-height: 120px;
  padding: 12px;
  display: flex;
  box-shadow: 0 5px 15px #00000040;
}

.task {
  color: #000;
  cursor: move;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  box-shadow: 0 5px 15px #00000026;
}

.is-dragging {
  color: #fff;
  background: #323232;
  scale: 1.05;
  box-shadow: 0 5px 15px #00000040;
}

/*# sourceMappingURL=index.f2cd31d1.css.map */
